html,
body {
  margin: 0 auto;
  font-family: 'Inter', 'Helvetica', sans-serif;
  font-size: 14px !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}

@import '~highlight.js/styles/atom-one-light.css';

.p-tooltip {
  max-width: fit-content; /* shows tooltip text in one line */
}

.icon-hover {
  cursor: pointer;
}

.p-inputnumber-input {
  width: 100%; /* keeps input element within column width */
}

.responsive {
  width: 100%;
  height: auto;
}

#plot .plot-container .modebar-container .modebar .modebar-group .modebar-btn {
  font-size: 23px !important;
}

.process-buttons {
  padding-top: 1em;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: white;
}
